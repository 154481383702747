import React, { useState, useEffect } from "react";
import axios from "axios";

import { API_ENDPOINT } from "../App";
import bellSound from '../assets/bell.mp3';
import "../styles/orders.css"

const Order = () => {
    const [orders, setOrders] = useState([]);
    const [lastOrderId, setLastOrderId] = useState(null);

    useEffect(() => {
        const fetchOrders = () => {
            axios.get(`${API_ENDPOINT}/list-orders`)
                .then(response => {
                    const fetchedOrders = response.data.ordersData.sort((a, b) => {
                        // Sort by order ID in descending order (newest first)
                        return new Date(b.order_id) - new Date(a.order_id);
                    });

                    if (fetchedOrders.length > 0 && fetchedOrders[0].order_id !== lastOrderId) {
                        setLastOrderId(fetchedOrders[0].order_id);
                        playSound();
                    }

                    setOrders(fetchedOrders);
                })
                .catch(e => console.error(e));
        };

        fetchOrders(); // Initial fetch
        const interval = setInterval(fetchOrders, 5000); // Poll every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [lastOrderId]);

    const playSound = () => {
        const audio = new Audio(bellSound);
        audio.play().then(() => {
            console.log("Audio played successfully!");
        }).catch(error => {
            console.error("Audio playback failed:", error);
        });
    };

    return (
        <div id="pageContainer">
            {/* Place Header Here in Future */}
            <div id="bodyContent">
                <div id="headerTop">Orders</div>
                {orders.map(order => (
                    <div key={order.order_id} className="order-card">
                        <div className="order-header">Order Received</div>
                        {/* <div className="details-wrapper">
                            <span className="details-text">ID:</span>
                            <span className="order-details-text">{order.order_id}</span>
                        </div> */}
                        <div className="details-wrapper details-divison">
                            <span className="details-text">Status: </span>
                            <span className="order-details-text">{order.order_status}</span>
                        </div>
                        <div className="details-wrapper">
                            <span className="details-text">Customer Details:</span>
                            <span className="order-details-text">&nbsp;</span>
                        </div>
                        <div className="customer-wrapper details-divison">
                            <div className="details-wrapper">
                                <span className="details-text">Name:</span>
                                <span className="order-details-text">{order.customer.name}</span>
                            </div>
                            <div className="details-wrapper">
                                <span className="details-text">Address:</span>
                                <span className="order-details-text">{order.customer.address}</span>
                            </div>
                            <div className="details-wrapper">
                                <span className="details-text">Email:</span>
                                <span className="order-details-text">{order.customer.email}</span>
                            </div>
                            <div className="details-wrapper">
                                <span className="details-text">Phone:</span>
                                <span className="order-details-text">{order.customer.phone_number}</span>
                            </div>
                        </div>
                        <div className="details-wrapper details-divison">
                            <span className="details-text">Items:</span>
                        </div>
                        <ul>
                            {order.items.map((item, index) => (
                                <li key={index}>{item.item_name} (x{item.quantity})</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            {/* Place Footer here */}
        </div>
    );
};

export default Order;
